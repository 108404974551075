import Toman from "../../Utilities/Toman";
import {Link} from "react-router-dom";
import CustomerSelect from "../../Customer/CustomerSelect";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import CustomerAddressList from "../../Customer/CustomerAddressList";
import {toast} from "react-toastify";
import ApiRoute, {isPrimaryShop} from "../../../helper/api";
import DriverSelect from "../../Common/DriverSelect";
import Modal from "../../Utilities/Modal";
import CustomerAddressEdit from "../../Customer/CustomerAddressEdit";
import {paymentMethodListCreateOrder} from "../../../helper/general";

export default function Step2({newOrder,setStep,step,setNewOrder}){
    const [selectedAddress , setSelectedAddress] = useState(newOrder?.address)

    const [customer , setCustomer] = useState(newOrder.customer)
    const [modal , setModal] = useState(false)
    const [update , setUpdate] = useState(0)
    const [editAddress , setEditAddress] = useState(null)

    async function refreshCustomer(){
        const _customer  = await ApiRoute.customer.single.fn(newOrder.customer._id)
        setCustomer(_customer.results)
        newOrder.customer = _customer.results
        setNewOrder(newOrder)
    }

    useEffect(() => {
        
        if (!newOrder?.order_state){
            newOrder.order_state = "legal"
        }
    }, []);

    return (
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">سفارش جدید</span></div>
                <div className="hidden lg:flex items-center justify-center text-sm xl:w-3/4 mx-auto mt-10">
                    <span className="text-purple-1">اطلاعات مشتری</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">نحوه ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>انتخاب کالا</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>انتخاب زمان ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>ثبت سفارش</span>
                </div>

                <div className="main-content lg:w-3/5 xl:w-2/5 mx-auto p-4 lg:p-8 mt-12">
                    <span className="block text-center text-purple-1 mb-6">نحوه ارسال و اطلاعات گیرنده</span>
                    <div className="lg:flex justify-between my-8 text-xs grid max-lg:gap-4">
                        <div>
                            <span className="text-gray-2">نام مشتری:</span>
                            <span className="text-gray-3 mr-1">{customer.personal.first_name + " " + customer.personal.last_name}</span>
                        </div>
                        <div>
                            <span className="text-gray-2">شماره تماس مشتری:</span>
                            <span className="text-gray-3 mr-1">{customer.contacts.cellphone}</span>
                        </div>
                        <div>
                            <span className="text-gray-2">نوع مشتری:</span>
                            {isPrimaryShop() && <span className="text-gray-3 mr-1">{customer.is_legal ? 'حقوقی' : 'حقیقی'}</span>}
                            {!isPrimaryShop() && <span className="text-gray-3 mr-1">
                                {customer.is_legal ? 'حقوقی / حقیقی' : 'عادی'}
                            </span>}
                        </div>
                    </div>

                    <CustomerAddressList setSelected={ (x) => {
                        setSelectedAddress(x)
                        newOrder.address = x
                    } } selectAble={true} editAction={ (address,customer) => {
                        setUpdate(Date.now())
                        setEditAddress(address.id)
                        setModal(true)
                    } } selected={selectedAddress} customClass={`main-content !shadow-none mx-auto p-0 mt-12`} customer={customer}/>

                    <div className="flex justify-between gap-3 mt-5">
                        <button onClick={refreshCustomer} className="btn-blue-light py-1 w-1/2 lg:w-36">بارگذاری مجدد</button>
                    </div>

                    <div className="form mt-6 grid grid-cols-2 gap-3 lg:gap-20">
                        <div>
                            <label className="!grid text-right">
                                <span className="text-right mb-3 pr-1">روش پرداخت</span>
                                <select onChange={ (e) => {
                                    newOrder.payment_method = e.target.value
                                } } className="!flex-none !mr-0">
                                    <option value={""}>انتخاب روش پرداخت</option>
\                                    { paymentMethodListCreateOrder().map( method => (
                                        <option selected={newOrder?.payment_method === method.name} value={method.name}>{method.label}</option>
                                    ) ) }
                                </select>
                            </label>
                        </div>
                        <div>
                            <label className="!grid text-right">
                                <span className="text-right mb-3 pr-1">نوع سفارش</span>
                                <select onChange={ (e) => {
                                    newOrder.order_state = e.target.value
                                } } className="!flex-none !mr-0">
                                    { !isPrimaryShop() && <option selected={newOrder?.order_state === "default"} value="default">پیشفرض - عادی</option>}
                                    <option selected={newOrder?.order_state === "legal"} value="legal">حقیقی</option>
                                    <option selected={newOrder?.order_state === "official"} value="official">حقوقی</option>
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className="flex justify-between lg:px-12 gap-3 mt-5">
                        <button onClick={ () => {
                            setStep(1)
                        } } className="btn-gray w-1/2 lg:w-36">بازگشت</button>
                        <button onClick={ () => {
                            if (!newOrder?.address){
                                toast.error("انتخاب آدرس ضروری میباشد")
                                return;
                            }
                            if(newOrder?.payment_method === "" || newOrder?.payment_method === null || newOrder?.payment_method === undefined){
                                toast.error("روش پرداخت ضروری است")
                                return;
                            }

                            if (newOrder?.order_state === "official" && !customer.is_legal){
                                toast.error("مشتری حقیقی نمیتواند سفارش حقوقی ثبت کند")
                                return;
                            }

                            if (newOrder?.order_state !== "official" && customer.is_legal){
                                toast.error("مشتری حقوقی نمیتواند سفارش حقیقی ثبت کند")
                                return;
                            }
                            setNewOrder(newOrder)
                            setStep(3)
                        } } className="btn-purple w-1/2 lg:w-36">انتخاب کالا</button>
                    </div>
                </div>

                { modal && <Modal isOpen={true} onClose={()=>{
                    setModal(false)
                }}>

                    <div>
                        {editAddress && <CustomerAddressEdit modalAction={ () => {
                            refreshCustomer()
                            setSelectedAddress(null)
                            newOrder.address = null
                            setModal(false)
                        } } asModal={true} modalUpdate={update} customer_id={customer._id} address_id={editAddress}/>}
                    </div>
                </Modal>}

            </div>
    )
}