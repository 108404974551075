import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {isOnEditState} from "../../../helper/product";
import Layout from "../../Layout";
import {Editor} from "@tinymce/tinymce-react";
import Buttons from "react-multi-date-picker/components/button";
import Select from "react-select";
import Modal from "../../Utilities/Modal";
import FileUpload from "../../FileUpload";

export function Step5({onEdit = false , defaultProduct = null}){

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)


    const [product,setProduct] = useState(defaultProduct)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [vars , setVars] = useState([])
    const [cards , setCards] = useState([])
    const [guarantee , setGuarantee] = useState('')
    const [stockName , setStockName] = useState('')
    const [summeryAttributes , setSummeryAttributes] = useState('')

    useEffect(() => {
        setProduct(defaultProduct)
    },[defaultProduct]);

    useEffect(() => {
        setVars(product?.variations ?? [])
        setCards(product?.cards ?? [])
        setGuarantee(product?.metadata?.guarantee ?? '')
        setSummeryAttributes(product?.metadata?.summery_attributes ?? '')
        setStockName(product?.metadata?.stock_name ?? '')
        setIsLoading(false)
    },[product]);

    function addVars() {
        setVars(vars => [...vars, {
            title: "عنوان #"+Date.now().toString(),
            url: "#"+Date.now().toString()
        }])
    }

    function addCards() {
        setCards(cards => [...cards, {
            title: "عنوان #"+Date.now().toString(),
            bg_color: "#FFFFFF",
            text_color: "#333"
        }])
    }

    async function handleSubmit() {

        if (guarantee.length < 1){
            toast.error("گارانتی ضروری میباشد")
            return;
        }

        if (stockName.length < 1){
            toast.error("آماده ارسال ضروری میباشد")
            return;
        }

        try {
            const response = await ApiRoute.product.create.stepFive.fn( params.get('product_id') , {
                variations : vars,
                cards : cards,
                guarantee : guarantee,
                stock_name : stockName,
                summery_attributes : summeryAttributes,
            } )
            setProduct(response.results)
            //toast.success("با موفقیت ذخیره شد")
            if (!onEdit){
                return navigate("/products/edit?product_id="+params.get('product_id'))
            }
        }catch (e) {
            toast.error(e.message)
        }
    }

    return (
        <>

            {isLoading === false && <div>

                {!onEdit && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">محصول جدید</span>
                </div>}


                <div className={!onEdit ? `main-content mx-auto py-6 px-5 my-5` : ''}>
                    {!onEdit && <span className="text-purple-1 pl-3 mb-6 block text-center">سایر مشخصات و کارت هدیه</span>}
                    <div className="border border-gray-7 p-3 lg:p-5 rounded">
                        <div className="form grid grid-cols-1 lg:grid-cols-9 items-center gap-12">
                            <div className="lg:col-span-4 grid grid-rows-2 gap-10">
                                <label>
                                    گارانتی
                                    <input onChange={(e) => { setGuarantee( e.target.value ) }} type="text" defaultValue={guarantee}/>
                                </label>
                                <label>
                                    آماده ارسال
                                    <input onChange={(e) => { setStockName( e.target.value ) }} type="text" defaultValue={stockName}/>
                                </label>
                            </div>
                            <label className="lg:col-span-5 !items-baseline">
                                ویژگی مهم
                                <textarea className="resize-none" cols="30" rows="5" onChange={ (e) => { setSummeryAttributes(e.target.value) } } defaultValue={summeryAttributes}></textarea>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="main-content mx-auto py-6 px-5 mt-10 mb-12">
                    <span className="text-purple-1 pl-3 mb-6 block text-center">کالاهای مشابه</span>
                    <div className="border border-gray-7 p-5 rounded">
                        <div className="form">

                            { vars?.length > 0 && vars.map((item,index) =>
                                <div key={`vars-item-${index}-${item.title}-${item.url}`} className="grid grid-cols-1 my-4 lg:grid-cols-9 w-full gap-12">
                                    <div className="lg:col-span-4">
                                        <label>
                                            نام کالا
                                            <input onChange={ (e) => { vars[index].title = e.target.value } } type="text" defaultValue={item.title}/>
                                        </label>
                                    </div>
                                    <div className="lg:col-span-5 lg:flex">
                                        <label className="!flex flex-auto">
                                            لینک کالا
                                            <input onChange={ (e) => { vars[index].url = e.target.value } } className="text-left" type="text" defaultValue={item.url}/>
                                        </label>
                                        <div className="flex items-center justify-end max-lg:mt-4">
                                            { index == vars.length - 1 && <button onClick={addVars} className="btn-green py-2 mr-4 rounded">افزودن</button> }
                                            <button onClick={() => setVars(vars.filter((xitem,xindex) => xindex !== index))} className="btn-red py-2 mr-4">حذف</button>
                                        </div>

                                    </div>

                                </div>
                            ) }

                            { vars.length < 1 && <button onClick={addVars} className="btn-green py-2 mr-4 rounded">افزودن</button> }

                        </div>
                    </div>

                </div>

                <div className="main-content mx-auto py-6 px-5 my-5">
                    <span className="text-purple-1 pl-3 mb-6 block text-center">کارت هدیه</span>
                    <div className="form">
                        <div className="grid grid-cols-1 lg:grid-cols-1 w-full gap-3">

                            {cards.length > 0 && cards.map( (item,index) => <div key={`cards-item-${index}-${item.title}-${item.bg_color}-${item.text_color}`} className="border border-gray-7 rounded p-3">
                                <div className="lg:flex justify-between items-center pb-4">
                                    <div className="flex gap-5">
                                        <label>
                                            رنگ بکگراند
                                            <input onChange={ (e) => {
                                                item.bg_color = e.target.value
                                            } } className="w-25 shadow" type="color" defaultValue={item.bg_color}/>
                                        </label>
                                        <label>
                                            رنگ متن
                                            <input onChange={ (e) => {
                                                item.text_color = e.target.value
                                            } } className="w-25 shadow" type="color" defaultValue={item.text_color}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="grid lg:flex gap-4">
                                    <label className="!flex flex-auto">
                                        نام
                                        <input onKeyUp={ (e) => {
                                            item.title = e.target.value
                                        } } type="text" defaultValue={item.title}/>
                                    </label>
                                    <div className="flex gap-4">
                                        { index == cards.length - 1 && <button onClick={addCards} className="btn-green py-2 rounded">افزودن</button> }

                                        <button onClick={() => setCards(cards.filter((xitem,xindex) => xindex !== index))} className="btn-red py-2">حذف</button>

                                    </div>

                                </div>
                            </div> )}

                            { cards.length < 1 && <button onClick={addCards} className="btn-green py-2 mr-4 rounded">افزودن</button> }

                        </div>
                    </div>
                </div>


                <div className={!onEdit ? `flex justify-between lg:w-1/3 mx-auto mt-10` : ''}>
                    { !onEdit && <Link to={`/products/create/step-4?product_id=${product._id}`} className="btn-gray w-36 py-1.5">بازگشت</Link> }

                    <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">
                        ذخیره سازی
                    </Buttons>
                </div>
            </div>
            }

        </>
    )
}