import ApiRoute from "../../../../helper/api";
import {useState} from "react";
import OrderDeliveryTime from "./OrderDeliveryTime";
import {HasAccessComponent} from "../../../../helper/permissions";
import { paymentMethodListEditOrder } from "../../../../helper/general";

export default function OrderPaymentMethod({order, setUpdateState}){
    const [paymentMethod, setPaymentMethod] = useState(order.payment_method._id);
    const [modal, setModal] = useState(false);
    const [myOrder, setMyOrder] = useState(null);
    const list = paymentMethodListEditOrder()

    async function handleUpdate(){
        const res = await ApiRoute.order.payment.updateMethod.fn(order._id,paymentMethod)
        if (res?.status_code == 200){
            if (paymentMethod === "shaparak" || paymentMethod === "cash_on_delivery"){
                setMyOrder(res.results)
                setModal(true)
            }
            setUpdateState(Date.now())
        }
    }

    return (
        <div
            className="form flex justify-between items-center pb-3 border-b border-b-gray-7 mt-4">
            <span>روش پرداخت</span>
            <div className="input-group mr-2">
                <select onChange={ (e) => {
                    setMyOrder(null)
                    setModal(false)
                    setPaymentMethod(e.target.value);
                } } className="input !mr-0">
                    {list.map( item =>
                        <option
                            selected={order.payment_method.name === item.value}
                            value={item.value}>{item.label}</option>)}

                </select>
                <HasAccessComponent permission={`change_payment order`} children={
                    <button onClick={handleUpdate} type="submit"
                            className="btn btn-green text-sm w-16 rounded-l-md p-2">ویرایش
                    </button>
                }/>
            </div>

            {(!order.is_shipping_in_person && modal) && <OrderDeliveryTime onCLoseModal={ () => {
                setMyOrder(null)
                setModal(false)
                setUpdateState(Date.now())
            } } hideButton={true} modalInit={modal} order={myOrder} setUpdateState={setUpdateState}/>}

        </div>
    )
}